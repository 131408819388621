import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      section a {
        color: #0036d9 !important;
        text-decoration: none;
        font-weight: 700;

        &:hover {
          color: #5a00f5 !important;
          text-decoration: none;
        }
      }

      .narrow {
        max-width: 775px;

        &.medium {
          max-width: 1000px;
        }

        &.large {
          .content {
            max-width: 1200px;
            width: 100%;
          }
        }
      }

      .simple-link {
        color: #000 !important;
        display: inline-block;

        span {
          padding-right: 10px;
          border-bottom: 1px solid black;
        }

        &:after {
          content: '';
          border-top: 1px solid black;
          border-right: 1px solid black;
          width: 5px;
          height: 5px;
          display: inline-block;
          transform: rotate(45deg);
          margin-left: -5px;
          margin-bottom: 2px;
        }

        &:hover {
          color: #000 !important;
        }
      }

      .center {
        margin-left: auto;
        margin-right: auto;
      }

      // TYPOGRAPHY
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        &.s1 {
          font-size: 24px !important;
          line-height: 32px !important;
          font-family: 'Averta Bold', sans-serif !important;

          &.size-40 {
            font-size: 40px !important;
          }

          @media (min-width: 768px) {
            font-size: 40px;
            line-height: 48px;
          }
        }

        &.s2 {
          font-size: 20px !important;
          line-height: 24px !important;
          font-family: 'Averta', sans-serif !important;

          @media (min-width: 768px) {
            font-size: 24px !important;
            line-height: 32px !important;
          }
        }

        &.s3 {
          font-size: 28px !important;
          line-height: 32px !important;
          font-weight: 800px !important;
          font-family: 'Averta Bold', sans-serif !important;

          @media (min-width: 768px) {
            font-size: 48px !important;
            font-style: unset !important;
            font-family: 'Averta', sans-serif !important;
            font-style: 'normal' !important;
          }
        }
      }

      .size-12 {
        font-size: 12px !important;
      }

      .size-40 {
        font-size: 40px !important;
      }

      .color-primary {
        color: #1990ff !important;
      }

      .color-secondary {
        color: #0d71cd !important;
      }

      .color-dark {
        color: #000000 !important;
      }

      .color-white {
        color: #ffffff !important;
      }

      .strike-through {
        text-decoration: line-through;
      }

      .center-desktop {
        @media (min-width: 768px) {
          text-align: center;
        }
      }

      .bold {
        font-weight: 800 !important;
      }

      .clean-wrap {
        white-space: nowrap;
      }

      // SPACING
      .mt-0 {
        margin-top: 0px;
      }

      .mb-4 {
        margin-bottom: 4px;
      }

      .mb-8 {
        margin-bottom: 8px;
      }

      .mb-12 {
        margin-bottom: 12px;
      }

      .mb-32 {
        margin-bottom: 32px;
      }

      .pb-0 {
        padding-bottom: 0px !important;
      }

      .bounce {
        display: inline-block;
        animation: bounce 2s cubic-bezier(0.42, 0, 0.75, 1) infinite 5s;
      }

      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        10% {
          transform: translateY(-4px);
        }
        20% {
          transform: translateY(0);
        }
        30% {
          transform: translateY(-2px);
        }
        40%,
        100% {
          transform: translateY(0);
        }
      }

      iframe {
        width: 100%;
        border: 0;
      }

      .content-transparent-desktop {
        @media screen and (max-width: 540px) {
          padding-bottom: 0 !important;
        }

        .content {
          @media screen and (min-width: 940px) {
            background-color: transparent !important;
            box-shadow: none;
          }
        }
      }

      .mobile-image-full {
        img {
          @media screen and (max-width: 940px) {
            width: 100% !important;
            position: relative;
            bottom: -4px;
          }
        }
      }

      .border-top-mobile {
        @media screen and (max-width: 939px) {
          border-top: 1px solid #ffffff !important;
        }
      }

      .text-center {
        text-align: center !important;

        @media (max-width: 540px) {
          text-align: left !important;
        }
      }

      .blue-punctuation-large {
        color: #1990ff !important;
        font-size: 1.25em !important;
        line-height: 0.75em !important;
      }

      .blue-punctuation-normal {
        color: #1990ff !important;
      }

      .white-punctuation-large {
        color: #ffffff !important;
        font-size: 1.25em !important;
        line-height: 0.75em !important;
      }

      .margin-x-auto {
        margin: 0 auto;
      }

      .no-padding-bottom {
        padding-bottom: 0 !important;
      }

      .video-container {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .video-container video {
        width: 100%;
        height: 100%;
        outline: none;
      }

      .video-container video::-webkit-media-controls {
        display: none !important;
      }

      .video-container:hover video::-webkit-media-controls {
        display: flex !important;
      }

      .video-container video::-moz-media-controls {
        display: none !important;
      }

      .video-container:hover video::-moz-media-controls {
        display: flex !important;
      }

      .video-container video::-ms-media-controls {
        display: none !important;
      }

      .video-container:hover video::-ms-media-controls {
        display: flex !important;
      }

      .hq-background {
        background-image: url('https://res.cloudinary.com/clearlink/image/upload/v1721171488/Clearlink/Index/hq-background-wide-black-desktop-6696fdc5b5eab_oq5apy.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;

        @media (min-width: 1800px) {
          height: 80vh;
        }
      }

      .underline {
        text-decoration: underline;
      }

      .nowrap {
        display: inline-block;
      }
    `}
  />
))

export default GlobalCss
